export const Projectimg =
[

    {
      img: "/sysmoweb1.png"
    },
    
    {
      img: "/sysmoweb2.png"
    },
    {
        img: "/sysmoweb3.png"
      },
    {
        img: "/sysmoweb4.png"
      },
    {
        img: "/img1.jpg"
      },
    {
        img: "/img2.jpg"
      },
    {
        img: "/img3.jpg"
      },
      {
        img: "/sysmoweb4.png"
      },
    {
        img: "/img1.jpg"
      },
    {
        img: "/img2.jpg"
      },
    {
        img: "/img3.jpg"
      },
    


  ]